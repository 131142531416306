import { Icon } from "components/Icon"
import { t } from "locales"
import React from "react"

import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Popover from "@mui/material/Popover"
import Typography from "@mui/material/Typography"
import { alpha, styled } from "@mui/material/styles"

import { toLocalDate } from "@nhi/utils"
import { IUpdate } from "data/contentData/api/content"

export interface IPopoverProps {
    updates: IUpdate[]
}

//check if the expiritionDate on the update is past
export default function UpdatesPopover({ updates }: IPopoverProps) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    )

    const isExpiritiondateInPast = (expiritionDate: Date) =>
        expiritionDate.setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)

    const _updates =
        updates?.filter(
            p =>
                p.expires === null ||
                isExpiritiondateInPast(new Date(`${p.expires}`)) === false
        ) ?? []
    if (!_updates.length) {
        return null
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? "article updates popover" : undefined

    return (
        <>
            <Grid
                item
                xs>
                <StyledButton
                    className="no-print"
                    aria-describedby={id}
                    variant="outlined"
                    color="secondary"
                    onClick={handleClick}
                    startIcon={
                        <Icon
                            fontSize="small"
                            name={"important-update"}
                        />
                    }>
                    {t["important-updates"]["important-update"]}
                </StyledButton>
            </Grid>
            <StyledPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}>
                <StyledIconButton
                    aria-label={t["close-popover"]}
                    onClick={handleClose}
                    size="large">
                    <Icon name="close" />
                </StyledIconButton>

                {_updates.map(update => (
                    <StyledGrid key={update.name}>
                        <Typography
                            variant="body1"
                            style={{ fontWeight: 700 }}>
                            {update.name}
                        </Typography>
                        <Typography variant="caption">
                            {t["published"]}:{" "}
                            {toLocalDate(
                                update.published,
                                process.env.NEXT_PUBLIC_LOCALE
                            )}
                        </Typography>
                        <StyledTypography variant="body2">
                            {update.text}
                        </StyledTypography>
                    </StyledGrid>
                ))}
            </StyledPopover>
        </>
    )
}

const StyledButton = styled(Button)(({ theme }) => ({
    float: "right",
    [theme.breakpoints.down("xs")]: {
        width: "100%",
        marginTop: theme.spacing(2)
    }
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    margin: theme.spacing(2),
    width: 36,
    height: 36,
    color: theme.palette.secondary.main,
    "&:hover, &:focus": {
        opacity: 1
    }
}))

const StyledPopover = styled(Popover)(({ theme }) => ({
    marginTop: theme.spacing(2),
    "& .MuiPopover-paper": {
        width: `calc(${theme.constants.width.contentMax}px - 32px)`,
        borderRadius: 0,
        border: "2px solid",
        borderColor: alpha(theme.palette.secondary.main, 1)
    }
}))

const StyledGrid = styled(Grid)(() => ({
    margin: "1em",
    "&:not(:last-child)": {
        borderBottom: "1px solid lightGrey"
    }
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    whiteSpace: "pre-line"
}))
