import { t } from "locales"
import { FC } from "react"

import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

import { IAuthor } from "data/contentData/api/content"

interface IProps {
    authors?: IAuthor[]
    authorsContentId: string
}

const Authors: FC<IProps> = ({ authors, authorsContentId }) => {
    const _authors = authors?.filter(p => p.current) ?? []
    if (!_authors.length) {
        return null
    }

    const author = _authors.map(({ name, title, organization }) => {
        return {
            name,
            fullText: [name, title, organization].filter(Boolean).join(", ")
        }
    })[0]

    return (
        <Grid
            item
            xs={12}>
            <Typography variant="caption">
                {t["last-approved-by"]}
                {":"}
            </Typography>

            <Tooltip title={author.fullText}>
                <Chip
                    clickable
                    component="a"
                    href={`#${authorsContentId}`}
                    rel="author"
                    color="secondary"
                    label={author.name}
                    size="small"
                    variant="outlined"
                    sx={{ ml: 1 }}
                />
            </Tooltip>
        </Grid>
    )
}

export default Authors
